const COLORS = {
  blueCadet: "#5f9ea0",
  blueLight: "#508DC6",
  blueMedium: "#3f51b5",
  blueDark: "#32587C",
  greenSeaMedium: "#3CB371",

  redLight: "#FFECEC",
  redMedium: "#FF7373",
  redDark: "#EB4343",
}

export default COLORS
