import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@material-ui/core/styles';
import muiTheme from '../../src/theme/MuiTheme';
import GlobalStyle from '../../src/theme/globalStyle'
import favicon from '../../src/assets/favicon.png'

export default function TopLayout(props) {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link href="https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;700&display=swap" rel="stylesheet"/>
        <link rel="icon" href={favicon} />

        <title>Alex Krippner | Code Enthusiast</title>
      </Helmet>
      <ThemeProvider theme={muiTheme}>
      <GlobalStyle/>
        {props.children}
      </ThemeProvider>
    </>
  );
}