import { createMuiTheme } from "@material-ui/core/styles"


const muiTheme = createMuiTheme({
 
  typography: {
    fontFamily: ["Josefin Sans", "sans serif"].join(","),

    h1: {
      fontSize: "18rem",     
    },

    h2: {
      fontSize: "15rem",
      '@media (max-width:600px)': {
        fontSize: '7rem'
      }
    },
    h3: {
      fontSize: "10rem",
      '@media (max-width:600px)': {
        fontSize: '6rem'
      }
    },
    h4: {
      fontSize: "7rem",
      '@media (max-width:600px)': {
        fontSize: '5rem'
      }
    },
    h5: {
      fontSize: "3.5rem",
    },
    h6: {
      fontSize: "2.5rem",
    },
    body1: {
      fontSize: '2rem',
    }
  },
})

export default muiTheme
